import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'components/common/Button';
import HomeCard from 'components/common/HomeCard';
import { useAppData } from 'hooks/useAppData';
import { languageSelector } from 'store/slices/language';
import './style.scss';

const CardScience = () => {
    const { t } = useTranslation();

    return (
        <HomeCard
            image="/assets/science.svg"
            text={t('home.card.science.content')}
            title={t('home.card.science.title')}
        />
    );
};

const CardKnowledge = () => {
    const { t } = useTranslation();

    return (
        <HomeCard
            image="/assets/head.svg"
            text={t('home.card.knowledge.content')}
            title={t('home.card.knowledge.title')}
        />
    );
};

const CardSerenity = () => {
    const { t } = useTranslation();

    return (
        <HomeCard
            image="/assets/serenity.svg"
            text={t('home.card.serenity.content')}
            title={t('home.card.serenity.title')}
        />
    );
};

const CardTools = () => {
    const { t } = useTranslation();

    return (
        <HomeCard
            image="/assets/documents.svg"
            text={t('home.card.tools.content')}
            title={t('home.card.tools.title')}
        />
    );
};

const HomeContent: FunctionComponent<{ onClick: VoidFunction }> = ({ onClick }) => {
    const { t } = useTranslation();
    const { language } = useSelector(languageSelector);
    const { INNOVATION_SITE_URL, MARCO_V4_URL, HESTER_URL, VASCO_V2_URL } = useAppData();

    return (
        <main role="main">
            <div className="home-content home-content__container">
                <div className="home-content__top">
                    <div className="home-cards">
                        <div className="home-cards--left">
                            <CardScience />
                            <CardKnowledge />
                        </div>
                        <div className="home-cards--right">
                            <CardSerenity />
                            <CardTools />
                        </div>
                    </div>
                    <div className="home-content__text--top">
                        <h2
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: t('home.content.cards_text.title') }}
                            className="home-content__title"
                        />
                        <p className="home-content__text">{t('home.content.cards_text.text1')}</p>
                        <p
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: t('home.content.cards_text.text2') }}
                            className="home-content__text"
                        />
                        <Button classes="button--black" onClick={onClick} text={t('home.start_button')} />
                    </div>
                    <div className="home-block-cards">
                        <div className="home-block-cards__wrapper">
                            <CardScience />
                            <CardKnowledge />
                        </div>
                        <div className="home-block-cards__wrapper">
                            <CardSerenity />
                            <CardTools />
                        </div>
                    </div>
                </div>
                <div className="home-expectation">
                    <div className="home-expectation__text">
                        <div>
                            <h2 className="home-content__title">{t('home.content.expectation.title')}</h2>
                            {/* eslint-disable-next-line react/no-danger */}
                            <p dangerouslySetInnerHTML={{ __html: t('home.content.expectation.content') }} />
                        </div>
                    </div>
                    <img alt="results-example" src="/assets/results-example.svg" />
                </div>
                <div className="home-your-way">
                    <img alt="your-way" src="/assets/thinking.svg" />
                    <div className="home-your-way__text">
                        <h2 className="home-content__title home-your-way__title">{t('home.content.your_way.title')}</h2>
                        <p>{t('home.content.your_way.text')}</p>
                        <ul className="home-your-way__other-products">
                            {/* eslint-disable react/no-danger */}
                            <li>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: t('home.content.your_way.marco', { MARCO_URL: MARCO_V4_URL }),
                                    }}
                                />
                            </li>
                            {language === 'fr' && (
                                <>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('home.content.your_way.hester', { HESTER_URL }),
                                            }}
                                        />
                                    </li>
                                    <li>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: t('home.content.your_way.vasco', { VASCO_URL: VASCO_V2_URL }),
                                            }}
                                        />
                                    </li>
                                </>
                            )}
                            {/* eslint-enable react/no-danger */}
                        </ul>
                        <Button
                            classes="home-your-way__button button--black"
                            onClick={() => {
                                window.location.href = INNOVATION_SITE_URL;

                                return null;
                            }}
                            text={t('home.content.your_way.button')}
                        />
                    </div>
                </div>
            </div>
            <div className="home-content__end">
                {/* eslint-disable-next-line react/no-danger */}
                <p dangerouslySetInnerHTML={{ __html: t('home.footer.start') }} className="home-content__end-title" />
                <Button classes="button--black" onClick={onClick} text={t('home.start_exercise_button')} />
            </div>
            <div className="home-content">
                <div className="home-content__container home-content__bottom">
                    <img alt="" className="home-content__bottom-image" src="/assets/investigator.svg" />
                    <div className="home-content__bottom-text">
                        <h2 className="home-content__title">{t('home.footer.mission.title')}</h2>
                        <p>{t('home.footer.mission.content')}</p>
                        <div className="home-content__bottom-logos">
                            <a className="home-content__logo" href="https://www.parisdescartes.fr/">
                                <img alt="Logo Universite Paris" src="/assets/logo_UniversiteParis.svg" />
                            </a>
                            <a className="home-content__logo" href="https://www.jobteaser.com">
                                <img alt="Logo Job Teaser" src="/assets/logo_JobTeaser_black.svg" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default HomeContent;
