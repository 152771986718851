import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import Diagram from 'components/Diagram';
import RestitutionActions from 'components/Restitution/RestitutionActions';
import { useAppData } from 'hooks/useAppData';
import useWindowSize from 'hooks/useWindowSize';
import './style.scss';

interface RestitutionProps {
    formId: string;
    higherDimension: string;
    onOpenPdfModalClick: VoidFunction;
}

const Restitution: FunctionComponent<RestitutionProps> = ({ formId, higherDimension, onOpenPdfModalClick }) => {
    const isMobile = 'xs' === useWindowSize().size;
    const { t } = useTranslation();
    const { MARCO_V4_URL } = useAppData();
    const textKey = `restitution.${higherDimension}.text`;

    return (
        <>
            <p className="restitution__title">{t('restitution.title')}</p>
            <p className="restitution__title--value">
                <span>{t(`restitution.${higherDimension}.title1`)}</span>
                <span>, </span>
                <span className="restitution__title--lowercase">{t(`restitution.${higherDimension}.title2`)}</span>
            </p>
            <div className="restitution__diagram">
                <Diagram formId={formId} />
            </div>
            <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t(textKey) }}
                className="base-margin"
            />
            <p className="base-margin">
                <span className="strong">{t('restitution.compagnies.title')}</span>
                <br />
                {t(`restitution.${higherDimension}.compagnies.text`)}
            </p>
            {isMobile ? (
                <RestitutionActions formId={formId} onDownloadPdfClick={onOpenPdfModalClick} />
            ) : (
                <Button
                    classes="button--black button--center base-margin"
                    onClick={onOpenPdfModalClick}
                    text={t('restitution.action.download')}
                />
            )}
            <section className="restitution__bottom">
                <h2 className="restitution__secondary-title">{t('restitution.next.title')}</h2>
                <p>{t('restitution.next.text')}</p>
                <Button
                    classes={`button--black ${isMobile ? 'button--block' : 'button--center'} base-margin`}
                    onClick={() => {
                        window.location.href = MARCO_V4_URL;

                        return null;
                    }}
                    text={t('restitution.action.next')}
                />
            </section>
        </>
    );
};

export default Restitution;
